import * as Model from '../model';
import React, { useEffect } from 'react';
import GoalChart from './GoalChart';

import Button from '@mui/material/Button';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';


function GoalHistory(props) {
  
  // the normal button
  const buttonIcon = props.count === null || props.count === 0 ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />;
  const buttonVariant = props.count === null || props.count === 0 ? "text" : "contained";
  const buttonColor = props.count === null || props.count === 0 ? "info" : "success";
  
  // the drop down button
  const options = props.options;
  if( options.length>0 && options[options.length-1].name != 'None' ){
    options.push({name:'None', id:null, sub_id:null})
  }
    

  var dropText = props.sub_id === null || props.sub_id === 0 ? props.name : 'xx'; // {options[selectedIndex].name}
  props.options.map((item, i) => item.id === props.sub_id ? dropText = item.name : null)
  if(props.count === null || props.count === 0){
    dropText = props.name
  }
  
 
  
  useEffect(() => {
    
  }, [props.date_key]);  
  
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);  
  
  
  const handleClick = () => {
    console.info(`You clicked `, options[selectedIndex], props.count);
    props.onIncrementGoal(props.index, props.id, 1, null, null);
  };

  const handleMenuItemClick = (event, index, pindex, pid, count, id, sub_id) => {
    //props.onIncrementGoal(props.index, props.id, props.count, option.id, props.sub_id)
    //console.log('handleMenuItemClick', event, index, pindex, pid, count, id, sub_id)
    props.onIncrementGoal(pindex, pid, count, id, sub_id);
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    // menu opens...
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    // menu closes...
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };  
  
  return (
    <>
    <td>
          { (props.type === 'dropdown')
            ? (
          <>
          <ButtonGroup size="small" ref={anchorRef} aria-label="split button">
            <Button variant={buttonVariant} startIcon={buttonIcon} color={buttonColor} onClick={handleClick}>{dropText}</Button>
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              variant={buttonVariant}
              color={buttonColor}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup> 
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => (
                        <MenuItem
                          key={`opt_${index}`}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index, props.index, props.id, props.count, option.id, props.sub_id)}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper> 
          </>          
        )
        : <Button size="small" color={buttonColor} variant={buttonVariant} startIcon={buttonIcon} onClick={() => props.onIncrementGoal(props.index, props.id, props.count, 0, 0)} style={{ whiteSpace: 'nowrap' }}>
          {props.name}
          </Button>
        }     
         
        </td>
        <td>
            <GoalChart
              chart_type={props.chart_type}
              history={props.history}
            />            
        </td>
        <td>
          {props.history.cnt_week}       
        </td>
        <td>
          {props.history.cnt_month}
        </td>
        <td>
          {props.history.cnt_year}
        </td>
         </>
  );
}

export default GoalHistory;