import * as Model from '../model';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import {
  Link as RouterLink,
} from 'react-router-dom';
import Link from '@mui/material/Link';

function DetailsPage() {

  const { group, id } = useParams();
  const [items, setItems] = useState([]);

  useEffect(() => {
    Model.get_details(group, id, setItems);    
  }, [setItems]);    
  
  console.log(items)
  
  return ( 
	<Stack gap={0} align="center">  		
    	<Container align="center">
      		<Typography variant="h6" style={{paddingTop: '5px'}} align="center">
			  	Details <a href="/Trends">Back</a>
				<Link component={RouterLink} to={`/Stats/${group}/${id}`}><ListItemText primary="Graph" /></Link>
		    </Typography>    
      		<Typography variant="h6" style={{paddingTop: '5px'}} align="center">
  				
		    </Typography>    
		</Container>
    	<Container align="center">
      		<Typography variant="h8" style={{paddingTop: '5px'}} align="center">{items.name}</Typography>    
		</Container>
		<Container align="center">
        <List>
		{items.info && items.info.map((item,index) => (
          <ListItem disablePadding key={`infol_${index}`}>
            <ListItemButton>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
		))} 
        </List>
		<table>
			<thead>
				<tr>
					<th>Date</th>
					<th>Value</th>
				</tr>
			</thead>
		<tbody>
			{items.data && items.data.map((item,index) => (
				<tr key={`infod_${index}`}>
					<td>{item.date}</td>
					<td align="right">{item.value}</td>
				</tr>
			))} 
		</tbody>
		</table>
		</Container>		
	</Stack>
  );
}

export default DetailsPage;
