import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Model from '../model';

import Stack from '@mui/material/Stack';
import Photo from './Photo';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Paper, Button } from '@mui/material'

import {
  Link as RouterLink,
} from 'react-router-dom';
import Link from '@mui/material/Link';

function Photos(props) {
	const { group } = useParams();
	const [items, setItems] = useState([]);
	
	useEffect(() => {
		Model.get_photos(12, group, setItems);    
	}, []);  
	
	console.log(items)
	
  return (
    <Container width="80%" height="80%" align="center">
		<Typography variant="h4" align="center">
            { items.length > 0 ? items[0].name : group }
        </Typography>	
		<Typography variant="h6" align="center">
            <Link component={RouterLink} to={`/Photos`}>Back to images</Link>
        </Typography>	
      <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
        {items.length > 0 && items[0].photos.map((image, index) => (
          <Grid item key={`ith_${index}`}>
            <a href={image.image}><img src={image.thumb} key={index} style={{height: '300px', width: 'auto'}}/></a>
            <br/>
            {image.date}
          </Grid>
        ))}
      </Grid>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </Container>
    
  );
}

export default Photos;