import * as Model from '../model';
import React, { useEffect, useState, useContext } from 'react';
import { MyContext } from '../Context';
import TagButton from './TagButton';
import GoalChart from './GoalChart';
import Grid from '@mui/material/Grid';
//import { emitter } from '../eventEmitter';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { emitter } from '../eventEmitter';

function TagHistories(props) {
  const { sharedData } = useContext(MyContext);
  //console.log('TagButtons: ', props.date_key)
  //const [count, setCount] = useState(0); // State to keep track of the count
  
  var initialCounts = props.tags.map((item,index) => item.count);
  var [counts, setCounts] = useState(initialCounts);
    
  useEffect(() => {    
    setCounts( props.tags.map((item,index) => item.count) )
  }, [props.tags]);   
  
  const incrementCount = (index, id, old_count) => {
    var new_count = old_count+1
    //console.log('incrementCount: index: ', index, ' id: ', id, ' old: ', old_count, ' new: ', new_count)
    Model.click_tag(id, sharedData.date_str, new_count, 0)
    setCounts(counts.map((count, i) => i === index ? new_count : count));
    
    //emitter.emit('tagUpdate', { message: "tagUpdate" });
  };  
  
  const decrementCount = (index, id, old_count) => {
    var new_count = old_count-1
    Model.click_tag(id, sharedData.date_str, new_count, 0)
    setCounts(counts.map((count, i) => i === index ? new_count : count));
    //emitter.emit('tagUpdate', { message: "tagUpdate" });
  };  
  
  const incrementMoreCount = (index, id, old_count) => {
    console.log(index, id, old_count);
  };  
  
  // drop down
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (index=null, id=null) => {
    if(id > 0){
      Model.click_tag(id, sharedData.date_str, 1, 0, )
      emitter.emit('tagUpdateMore', { message: "tagUpdateMore" });
    }
    setAnchorEl(null);
  };
    
  return (
    <>
      {props.tags.map((item,index) => ( 
        <React.Fragment key={`taggroup_${index}`}>  
        <tr>
          <td> 	
            <TagButton         
              id={item.id} name={item.name} 
              date_key={props.date_key}
              count={counts[index]} 
              show={item.show} 
              index={index}
              onIncrement={() => incrementCount(index, item.id, counts[index])} 
              onDecrement={() => decrementCount(index, item.id, counts[index])} 
              />
          </td>
          <td>
            <GoalChart
                chart_type={item.chart_type}
                history={item.history}
              />
          </td>
          <td>
            {item.history.cnt_week}       
          </td>
          <td>
            {item.history.cnt_month}
          </td>
          <td>
            {item.history.cnt_year}
          </td>          
        </tr>    
        </React.Fragment>    
    ))}    
	</>
  );
}

export default TagHistories;