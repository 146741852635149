import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

function Event(props) {
  return (
    <>
      <Alert severity="info">
        {props.event}
      </Alert> 
    </>
  );
}

export default Event;