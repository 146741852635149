//import React, { useState, useEffect } from 'react';
import {
  Link as RouterLink,
} from 'react-router-dom';

import Card from '@mui/material/Card';
//import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions, Button } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '5px',
  margin: '15px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function WeekHeaderCard(props) {
  
  //var state = props.status == 'on' ? 'on' : 'null';
  
  return (
    
    <Paper elevation={3}>
<Stack spacing={3}>
  <Item>{props.day}    </Item>
  <Box>
            {props.icons.map((item, index) => (
              <i key={`header_${index}`} className={`icon ${item.status==='on'?'on':'null'} fa-regular ${item.class}`} />
            ))}          

  </Box>
</Stack>    
            
    </Paper>
    
  );
}

export default WeekHeaderCard;