import * as Model from '../model';
import React, { useEffect } from 'react';

import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import Button from '@mui/material/Button';

function GoalChart(props) {

  const [chart_type, setChart_type] = React.useState(props.chart_type);
  const [chart_points, setChart_points] = React.useState(props.history.day_by_week);

  var chart_labels = {
    'day_by_week'   : 'Prior Week',  
    'day_by_month'  : 'Prior Month', 
    'week_by_qtr'   : 'Prior Quarter',  
    'week_by_year'  : 'Year by Week', 
    'month_by_year' : 'Prior Year',
  } 
  const handleChartClick = (event, chart_type) => {
    console.log("handleChartClick ", chart_type)
    if(chart_type == 'day_by_week'){
      setChart_type('day_by_month');
      setChart_points(props.history.day_by_month)      
    }else if(chart_type == 'day_by_month'){
      setChart_type('week_by_qtr');
      setChart_points(props.history.week_by_qtr)            
    }else if(chart_type == 'week_by_qtr'){
      setChart_type('week_by_year');
      setChart_points(props.history.week_by_year)          
    }else if(chart_type == 'week_by_year'){
      setChart_type('month_by_year');
      setChart_points(props.history.month_by_year)          
    }else if(chart_type == 'month_by_year'){
      setChart_type('day_by_week');
      setChart_points(props.history.day_by_week)            
    }else{
      console.log("oops ", chart_type)
    }
  };

	return (
		<React.Fragment>
        	<SparkLineChart
          	plotType="bar"
          	data={chart_points.length > 0 ? chart_points : [1]}
          	height={20}
          	width={100}
          	showHighlight={true}
          	showTooltip={true}
        	/>            
        	<Button
          	id="basic-button"
          	onClick={(event) => handleChartClick(event, chart_type)}
			size="small"
        	>
          	{chart_labels[chart_type]}
        	</Button> 	
		</React.Fragment>
	);
}

export default GoalChart;