import React, { createContext, useState } from 'react';

export const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [sharedData, setSharedData] = useState({}); // Replace with your initial state

  // Update this to include the data and methods you want to share
  const contextValue = {
    sharedData,
    setSharedData,
  };

  return (
    <MyContext.Provider value={contextValue}>
      {children}
    </MyContext.Provider>
  );
};
