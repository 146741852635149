import * as Model from '../model';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import DarkUnica from 'highcharts/themes/dark-unica';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


const ChartDark = ({ options }: { options: any }) => {
  DarkUnica(Highcharts);
  Highcharts["_modules"]["Extensions/Themes/DarkUnica.js"].apply();
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
/*
const ChartLight = ({ options }: { options: any }) => {
  SandSignika(Highcharts);
  Highcharts["_modules"]["Extensions/Themes/SandSignika.js"].apply();

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

      	<Slider
					getAriaLabel={() => 'Minimum distance shift'}
					value={dateRange}
					onChange={handleSliderChange}
					valueLabelDisplay="auto"
					valueLabelFormat={ (value) => { return items.chart_options.xAxis[0].categories[value]; } }
					min={slider_minmax[0]}
        			max={slider_minmax[1]}
					disableSwap
					/>

*/

function StatsPage() {  
  
  const { group, id } = useParams();
  var init_chips = [];
  if(group && id){
    init_chips = [ { source:group, id:id, name:'xx' } ];    
  }
  
  const initialState = {
			'chart_options' : {title:{text:'Loading...'}, xAxis:[ {categories:[]} ]},
			'chip_options' : [],
			'grouping' : 'week',
            'scope': 'all',
			'chips_selected' : init_chips,
		}
  const [items, setItems] = useState(initialState);
  const [sortAsc, setSortAsc] = useState(true);
  var sort_label = sortAsc ? "Sort Ascending" : "Sort Descending";  
  
  // slider controls
  var slider_minmax = [ 0, items.chart_options.xAxis[0].categories.length];
  var minDistance = 2;
  const [dateRange, setDateRange] = useState([0, items.chart_options.xAxis[0].categories.length]);
  
  const handleSliderChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setDateRange([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setDateRange([clamped - minDistance, clamped]);
      }
    } else {
      setDateRange(newValue);
    }
  };  
  
  // checkbox controls
  const handleSortChange = (event) => {
	  setSortAsc(!sortAsc);
	  sort_label = sortAsc ? "Sort Ascending" : "Sort Descending";
	  
		const newItems = {
    		...items,
			sortAsc: sortAsc
		};	  
		Model.load_chart(setItems, newItems);
  }
  // week/month/day
  const handleGroupingClick = (group) => {
	const newItems = {
    	...items,
    	grouping: group
	};	  
	Model.load_chart(setItems, newItems);
  };
  
  // all/week/month/year
  const handleScopeClick = (scope) => {
	const newItems = {
    	...items,
    	scope: scope
	};	  
	Model.load_chart(setItems, newItems);
  };
  
  
  
  const opts = {
        chart: {
            events: {
                update: function() {
                    // This function will be called after the chart updates
                    //console.log('Chart updated!');
					//items.chips_selected = [{source: "metrics", id: 10, name: "Zinc1"}, {source: "metrics", id: 10, name: "Zinc2"}];					
                }
            }
        },	  
		'title': { text: items.chart_options.title.text },
		'xAxis': items.chart_options.xAxis,	
		'yAxis': items.chart_options.yAxis,
		'series': items.chart_options.series,
	};  

  const initalLoadCallback = () => {
	slider_minmax = [ 0, items.chart_options.xAxis[0].categories.length];
	const newRange = [0, items.chart_options.xAxis[0].categories.length];
	setDateRange(newRange);
  };


  useEffect(() => {
    Model.load_chart(setItems, items, initalLoadCallback);    
  }, [setItems]);   
  
  return ( 
      <Container>
          <Stack gap={2}>
            <Typography variant="h6" gutterBottom align="center">Stats</Typography>
			<Autocomplete 
    			multiple
				value={items.chips_selected}
    			id="tags-standard"
    			options={items.chip_options}
    			getOptionLabel={(option) => option.name}
    			defaultValue={[]}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				onChange={(event, newValue) => {
					//console.log(newValue)
					const newItems = {
    					...items,
    					chips_selected: newValue
					};	  
                    //console.log(newValue);
					Model.load_chart(setItems, newItems);					
          		}}
    			renderInput={(params) => (
      			<TextField
        			{...params}
        			variant="standard"
        			label="Metrics"
        			placeholder="Select metrics to chart"
      			/>
    			)}
  			/>			
			<ChartDark 
				options={opts}
			/>
			<Container>
                <FormControlLabel control={<ToggleButtonGroup
                    color="primary"
                    value={items.grouping}
                    exclusive
                  >
                  <ToggleButton value="day" onClick={() => handleGroupingClick('day')}>Day</ToggleButton>
                  <ToggleButton value="week" onClick={() => handleGroupingClick('week')}>Week</ToggleButton>
                  <ToggleButton value="month" onClick={() => handleGroupingClick('month')}>Month</ToggleButton>
                </ToggleButtonGroup>} label="Grouping: " labelPlacement="top" />
                
                <FormControlLabel control={<ToggleButtonGroup
                    color="primary"
                    value={items.scope}
                    exclusive
                  >
                  <ToggleButton value="day" onClick={() => handleScopeClick('all')}>Day</ToggleButton>
                  <ToggleButton value="week" onClick={() => handleScopeClick('week')}>Week</ToggleButton>
                  <ToggleButton value="month" onClick={() => handleScopeClick('month')}>Month</ToggleButton>
                  <ToggleButton value="quarter" onClick={() => handleScopeClick('quarter')}>Quarter</ToggleButton>
                  <ToggleButton value="year" onClick={() => handleScopeClick('year')}>Year</ToggleButton>
                </ToggleButtonGroup>} label="Scope: " labelPlacement="top" />                
        
				<FormControlLabel control={<Switch checked={sortAsc} onChange={handleSortChange} />} label={sort_label}  labelPlacement="top" />
		
			</Container>
			  
          </Stack>        
      </Container>
  );
}

export default StatsPage;
