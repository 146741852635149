import React, { useEffect } from 'react';


import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';

function TagButton(props) {
  
  const buttonVariant = props.count === null || props.count === 0 ? "outlined" : "";
  const buttonColor = props.count === null || props.count === 0 ? "primary" : "success";
  const handleDelete = props.count === null || props.count === 0 ? null : props.onDecrement;
  const badgeCount = props.count === null || props.count <= 1 ? 0 : props.count;
  
  useEffect(() => {
    //console.log('Button: ', props.date_key, props.name, props.count)
  }, [props.date_key, props.count]);    
  
  return (
    <Badge badgeContent={badgeCount} color="primary">
      <Chip clickable label={props.name} color={buttonColor}  variant={buttonVariant} onDelete={handleDelete} onClick={props.onIncrement} />
    </Badge>
  );
}

export default TagButton;