import * as Model from '../model';
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import WeekHeader from '../components/WeekHeader';
import Goals from '../components/Goals';
import Event from '../components/Event';
import TagButtons from '../components/TagButtons';
import Measurements from '../components/Measurements';

//import { MyProvider } from '../Context';
import { MyContext } from '../Context';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

import {
  Link as RouterLink,
} from 'react-router-dom';
import Link from '@mui/material/Link';
import { emitter } from '../eventEmitter';

function HomeAlt() {
  const { date_key } = useParams();
  const [items, setItems] = useState([]);
  const [state, setState] = useState([]);
  const [counter, setCounter] = useState(1);
  
  const { sharedData, setSharedData} = useContext(MyContext);

  useEffect(() => {
    Model.load_home(date_key, setItems, setState, (date, date_key) => { setSharedData({ date:date, date_str:date_key }) } );    
    emitter.on('tagUpdateMore', () => { setCounter(counter + 1); });
  }, [date_key, setState, setSharedData, counter]);
  
  return ( 
      <Container>
          <Stack gap={2}>
            <Stack gap={0} align="center">
                <Typography variant="h6" style={{paddingTop: '5px'}} align="center">
                  {sharedData.date} {date_key ? (<Link component={RouterLink} to={`/`}>(HomeAlt)</Link>) : ''}
                </Typography>
            </Stack>
            <WeekHeader date_key={date_key}/>
            <Event event={state.event}/>
            <Goals date_key={date_key}/>
            {items.map((item,index) => (                
              <React.Fragment key={`tag_group_${item.name}`}>
                <TagButtons tags={item.tags} oldTags={item.oldTags} date_key={date_key}/>
              </React.Fragment>
            ))}          
            <Measurements />
            <Typography  gutterBottom align="center">Env: {process.env.REACT_APP_ENV} <a href="javascript:window.location.reload();">Reload</a></Typography>
          </Stack>              
      </Container>

  );
}

export default HomeAlt;
