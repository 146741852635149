import * as Model from '../model';
import React, { useState, useEffect } from 'react';

import MeasureCard from '../components/MeasureCard';
import Grid from '@mui/material/Grid';

import Trends from '../components/Trends';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';


import {
  Link as RouterLink,
} from 'react-router-dom';
import Link from '@mui/material/Link';

function TrendsPage() {
  
  const [items, setItems] = useState([]);
  const [other, setOther] = useState([]);
  
  useEffect(() => {
    Model.get_measurements(false, setItems, setOther);    
  }, [setItems, setOther]);  
  
  return (
    <Container align="center">
      <Typography variant="h6" style={{paddingTop: '5px'}} align="center">All Trends</Typography>    
      <Grid container spacing={.5} direction="row" alignItems="center" justifyContent="center">
        {items.map((item, index) => (                    
          <Grid key={`trem_${index}`} item alignItems="center" justifyContent="center">
            <MeasureCard card={item}/>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={.5} direction="row" alignItems="center" justifyContent="center">
         <List dense={true}>      
           <ListSubheader component="div" id="nested-list-subheader">
             Other Metrics
           </ListSubheader>                     
          {other.map((item, index) => (    
             <ListItem key={`other_${index}`}>
              <Link component={RouterLink} to={`/Details/${item.source}/${item.id}`}><ListItemText primary={item.name} /></Link>               
             </ListItem>          
          ))}
        </List>
      </Grid>
    </Container>
  );
}

export default TrendsPage;
