import * as Model from '../model';
import React, { useState, useEffect, useContext } from 'react';

import { MyContext } from '../Context';

import GoalHistory from './GoalHistory';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'
import { emitter } from '../eventEmitter';
import TagHistories from '../components/TagHistories';


function GoalHistories(props) {
  const { sharedData } = useContext(MyContext);
  const [items, setItems] = useState([]); // the main list of goals
  const [counts, setCounts] = useState([]); // the counts for each goal
  

  
  
  console.log(items)
  useEffect(() => {
    Model.get_goals(props.date_key, setItems, (data) => { 
      setCounts(data.map(item => item.count)); 
      //setSubids(data.map(item => item.count)); 
    } );
  }, [props.date_key]); 	
  
    
  const incrementGoal = (index, id, old_count, sub_id, old_sub_id) => {    
    //console.log('incrementGoal1: ', index, id, old_count, sub_id, old_sub_id)
    if( old_count == null){
      // selected the none option, zero it out
      console.log('old_count == null')
      old_count = 1;
      sub_id = 0;
    }else if(sub_id > 0 && sub_id === old_sub_id){
      // selected the same thing, do nothing
      console.log('sub_id > 0 && sub_id === old_sub_id')
      old_count = 1
    }else if(sub_id && old_sub_id){
      console.log('sub_id && old_sub_id')
      // selected the same thing, do nothing
    }else if(old_count >= 1){ 
      // increments (turned on, so turn off)
      console.log('old_count >= 1')
      old_count = 0 
    }else{ 
      console.log('else')
      // it was zero, set to 1
      old_count = 1 
    }
    //console.log('incrementGoal2: ', old_count)
    Model.click_tag(id, sharedData.date_str, old_count, sub_id, () => {
      Model.get_goals(sharedData.date_str, setItems, (data) => { 
        setCounts(data.map(item => item.count)); 
        emitter.emit('tagUpdate', { message: "tagUpdate" });
      } );      
    });
    
  };
  
  return (    
      <Paper elevation={0}>
        <table cellPadding={5}>
          <tbody>
          <tr style={{borderBottom: '0px solid white'}}>
            <td>
              Goals
            </td>
            <td>
              Trend Graph
            </td>
            <td>
              W
            </td>
            <td>
              M
            </td>
            <td>
              Y
            </td>          
          </tr>
          {items.map((item,index) => (
            <tr key={`goal_${index}`}>
            <React.Fragment key={`goalfr_${index}`}>
              <GoalHistory name={item.name} 
                date_key={props.date_key}
                options={item.options} type={item.type} 
                streak={item.streak} misses={item.misses} 
                month={item.month} year={item.year} 
                record={item.record}
                streak_date={item.streak_date}
                current={item.current}
                count={counts[index]}
                index={index}
                id={item.id}
                sub_id={item.sub_id}
                history={item.history}
                chart_type={item.chart_type}
                onIncrementGoal={ incrementGoal } 
              />
            </React.Fragment>
            </tr>
          ))}
          
          {props.tags.map((item,index) => (
            <React.Fragment key={`tag_group_${item.name}`}>
              <tr>
                <td>{item.name}</td>
              </tr>
              <TagHistories tags={item.tags} oldTags={item.oldTags} date_key={props.date_key} />
            </React.Fragment>
          ))}                   
                   
          </tbody>
        </table>    
      </Paper>
    
  );
}

export default GoalHistories;