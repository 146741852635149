import * as Model from '../model';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';


export default function SignIn() {
  const [invalidLoginVisible, setInvalidLoginVisible] = useState(false);
  const navigate = useNavigate();
  
  const handleAuthResponse = (response) => {
	  console.log(response);  
	  
	  if(response.success == true){
		setInvalidLoginVisible(false)
		localStorage.setItem('authToken', response.token);  
		navigate('/'); 
	  }else{	  
	  	setInvalidLoginVisible(true)
  	  }
  };
	
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
	Model.authenticate_user(data.get('email'), data.get('password'), handleAuthResponse);    
  };

  return (
    
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
			{
  			invalidLoginVisible && <Alert severity="error">Invalid login</Alert>
			}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
 
  );
}