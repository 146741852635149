import * as Model from '../model';
import React, { useState, useEffect } from 'react';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import {
  Link as RouterLink,
} from 'react-router-dom';
import Link from '@mui/material/Link';

function PhotoPage() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    Model.get_photos(2, null, setItems);    
  }, []);   
  
  return (
    <Container>
      <Typography variant="h6" style={{paddingTop: '5px'}} align="center">Photos</Typography>
     {items.map((group,index) => (
    <ImageList sx={{ width: 420}} key={`il_${index}`}>
      <ImageListItem key="Subheader" cols={2}>
        <Typography variant="h5">
        <Link component={RouterLink} to={`/Photos/${group.group}`}>{group.name}</Link>
        </Typography>
      </ImageListItem>
      {group.photos.map((item,index) => (
        <ImageListItem key={`ph_${index}`}>
          <img
            src={item.thumb}
            alt={item.date}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.date}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.date}`}
              >
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
    ))}
    </Container>
  );
}

export default PhotoPage;



const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    author: '@bkristastucchio',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  
];