import React from 'react';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('authToken');

    if (!token) {
        // Redirect to Signin page if there's no token
        return <Navigate to="/Signin" />;
    }

    return children;
};
