import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//import Typography from '@mui/material/Typography';
//import { CardActionArea, CardActions, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';

import {
  Link as RouterLink,
} from 'react-router-dom';
import Link from '@mui/material/Link';

function MeasureCard(props) {
  
  // up, down, neutral | success, danger, secondary
  //const current_trend = props.card.current_trend
  
  // the direction of the arrow
  var current_icon = null;
  switch(props.card.current_trend) {
    case 'up':	current_icon = <TrendingUpIcon />;	    break;
    case 'down':	current_icon = <TrendingDownIcon />;	break;
    case 'good':	current_icon = <ThumbUpIcon />;	break;
    case 'bad':	current_icon = <WarningAmberIcon />;	break;
    case 'flat':	
    default:      current_icon = <TrendingFlatIcon />;	break;
  }  
  
  // the color
  const current_mode = props.card.current_mode === 'good' ? 'success' : 
    props.card.current_mode === 'bad' ? 'error' : 'info';
  
  const chartType = props.card.chart_type == 'bar' ? 'bar' : 'line';
  //console.log(props.card.name, props.card.current_value, props.card.points.length)
  return (
    <Paper className="measure_card">
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={.5}>
          <Typography variant="h6" style={{paddingTop: '5px'}} align="center">
            <Link component={RouterLink} to={`/Details/${props.card.group}/${props.card.id}`}>{props.card.name}</Link>
          </Typography>
          <Typography variant="h8" align="center">
            {`Target: ${props.card.target_str}`}
          </Typography>        
          <Chip label={props.card.current_value} color={current_mode} style={{paddingTop: '5px'}} icon={current_icon}/>
          {props.card.points.length > 0 ?
            <SparkLineChart
              plotType={chartType}
              data={props.card.points}
              height={50}
              width={100}
              showHighlight={true}
              showTooltip={true}
            />
            : ''
          }
          <Typography variant="h8" style={{paddingTop: '5px'}} align="center">
            {props.card.last_value_str}
          </Typography>
        </Stack>

    </Paper>
  );
}

export default MeasureCard;