import {
  Link as RouterLink,
} from 'react-router-dom';

import * as Model from '../model';
import React, { useState, useEffect } from 'react';

import WeekHeaderCard from './WeekHeaderCard';
import { emitter } from '../eventEmitter';
import Grid from '@mui/material/Grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';



function WeekHeader(props) {  
  const [items, setItems] = useState([]);
  const [counter, setCounter] = useState(1);
  
  useEffect(() => {
    //console.log('Load WeekHeader: ', props.date_key);
    Model.get_weekheader(props.date_key, setItems);
    emitter.on('tagUpdate', () => { setCounter(counter + 1); });
  }, [counter, props.date_key]);  
  
  return ( 
    <Paper square={false}>
    <table cellPadding="0" cellSpacing="0" align="center" width="100%">
      <tbody>
      <tr>
          {items.map((item, index) => (
            <td key={`wh_head_${index}`} style={{  borderBottom: '1px solid black', padding: '5px', borderRight: '1px solid black', minWidth: '45px' }}>            
              <Link component={RouterLink} to={`/${item.day_key}`}>
                <span className="week_head" style={{whiteSpace: 'nowrap', paddingRight:1 + 'px', float:'left'}}>{item.dayOfWeek}</span>
                <span className="week_head" style={{whiteSpace: 'nowrap', paddingLeft:1 + 'px', float:'right'}}>{item.day}</span>
              </Link>
            </td>
          ))}
      </tr>
      <tr>
          {items.map((item, index) => (
            <td key={`whitem1_${index}`} style={{ padding: '5px', borderRight: '1px solid black', borderBottom: '0px' }} align="center">
              <Link component={RouterLink} to={item.day_key}>
                {item.icons.map((item, index) => (
                  <i key={`whitem3_${index}`} className={`week_icon ${item.status==='on'?'on':'null'} ${item.class}`} />
                ))} 
              </Link>
            </td>
          ))}      
      </tr>
    </tbody>
    </table>
    </Paper>
  );
}

export default WeekHeader;
