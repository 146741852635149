import Stack from '@mui/material/Stack';

function SettingsPage() {
  
  return ( 
    <div className="App">
      <header className="App-header">

      </header>   
      <header className="App-content">
      <Stack gap={2}>
          <div>Settings</div>
      </Stack>
      </header>
    </div>
  );
}

export default SettingsPage;
