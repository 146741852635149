import React, { useState, useEffect } from 'react';
import axios from 'axios';


function YearPage() {
  
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get('/load_home');
        setItems(result.data.data.tags);
      } catch (error) {
    	  console.error(error);
      }
    };
    fetchData();
  }, []);   
  
  return ( 
    <div className="App">
      <header className="App-header">
        {items.count}
      </header>   
      <header className="App-content">

      </header>
    </div>
  );
}

export default YearPage;
