import * as Model from '../model';
import React, { useState, useEffect } from 'react';

import MeasureCard from './MeasureCard';
import Grid from '@mui/material/Grid';

function Measurements() {
  const [pitems, setpItems] = useState([]);
  const [other, setOther] = useState([]);
  
  useEffect(() => {
    Model.get_measurements(true, setpItems, setOther);    
  }, []);  
  
  return (
    <Grid container spacing={.5} direction="row" alignItems="center" justifyContent="center">
      {pitems.map((item, index) => (                    
        <Grid key={`measx_${index}`} item align="center" justifyContent="center">
          <MeasureCard card={item}/>
        </Grid>
      ))}
    </Grid>
  );
}

export default Measurements;