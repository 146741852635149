import * as Model from '../model';
import React, { useEffect } from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
//import Button from 'react-bootstrap/Button';
import Grid from '@mui/material/Grid';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import CancelIcon from '@mui/icons-material/Cancel';
//import WhatshotIcon from '@mui/icons-material/Whatshot';
// <i  key={`header_${index}`} className="icon fa-regular fa-circle-check" />


import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

function Goal(props) {
  
  // the normal button
  const buttonIcon = props.count === null || props.count === 0 ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />;
  const buttonVariant = props.count === null || props.count === 0 ? "text" : "contained";
  const buttonColor = props.count === null || props.count === 0 ? "info" : "success";
  
  // the drop down button
  const options = props.options;
  if( options.length>0 && options[options.length-1].name != 'None' ){
    options.push({name:'None', id:null, sub_id:null})
  }
    

  var dropText = props.sub_id === null || props.sub_id === 0 ? props.name : 'xx'; // {options[selectedIndex].name}
  props.options.map((item, i) => item.id === props.sub_id ? dropText = item.name : null)
  if(props.count === null || props.count === 0){
    dropText = props.name
  }
  
  // check array and medals
  const checkIcon = props.count === null || props.count === 0 ? "icon fa-regular fa-square" : "icon fa-regular fa-square-check";
  const count = props.current ? props.current : 0;
  const medal_count = Math.floor(count / 30); // months
  const award_count = Math.floor((count - medal_count*30) / 7); // weeks
  const check_count = count - (medal_count*30) - (award_count*7);
  //console.log(props.name, props.current, count, medal_count, award_count,check_count);
  
  useEffect(() => {
    
  }, [props.date_key]);  
  
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);  
  const as_pill = props.as_pill
  
  const handleClick = () => {
    console.info(`You clicked `, options[selectedIndex], props.count);
    props.onIncrementGoal(props.index, props.id, 1, null, null);
  };

  const handleMenuItemClick = (event, index, pindex, pid, count, id, sub_id) => {
    //props.onIncrementGoal(props.index, props.id, props.count, option.id, props.sub_id)
    //console.log('handleMenuItemClick', event, index, pindex, pid, count, id, sub_id)
    props.onIncrementGoal(pindex, pid, count, id, sub_id);
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    // menu opens...
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    // menu closes...
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };  
  
  return as_pill ? (
    <>
          { (props.type === 'dropdown')
            ? (
          <>
          <ButtonGroup size="small" ref={anchorRef} aria-label="split button">
            <Button variant={buttonVariant} startIcon={buttonIcon} color={buttonColor} onClick={handleClick}>{dropText}</Button>
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              variant={buttonVariant}
              color={buttonColor}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup> 
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => (
                        <MenuItem
                          key={`opt_${index}`}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index, props.index, props.id, props.count, option.id, props.sub_id)}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper> 
          </>          
        )
        : <Button size="small" color={buttonColor} variant={buttonVariant} startIcon={buttonIcon} onClick={() => props.onIncrementGoal(props.index, props.id, props.count, 0, 0)} style={{ whiteSpace: 'nowrap' }}>
          {props.name}
          </Button>
        }    
    </>    
  ) : (
    <>
    <td>
          { (props.type === 'dropdown')
            ? (
          <>
          <ButtonGroup size="small" ref={anchorRef} aria-label="split button">
            <Button variant={buttonVariant} startIcon={buttonIcon} color={buttonColor} onClick={handleClick}>{dropText}</Button>
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              variant={buttonVariant}
              color={buttonColor}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup> 
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => (
                        <MenuItem
                          key={`opt_${index}`}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index, props.index, props.id, props.count, option.id, props.sub_id)}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper> 
          </>          
        )
        : <Button size="small" color={buttonColor} variant={buttonVariant} startIcon={buttonIcon} onClick={() => props.onIncrementGoal(props.index, props.id, props.count, 0, 0)} style={{ whiteSpace: 'nowrap' }}>
          {props.name}
          </Button>
        }     
         
        </td>
        <td>
          <React.Fragment>
            {Array.from({ length: medal_count }, (_, index) => (            
              <MilitaryTechIcon key={`medal_${index}`} color="primary" />
            ))}
              {Array.from({ length: award_count }, (_, index) => (            
              <EmojiEventsIcon key={`award_${index}`} color="primary" />
            ))}
              {Array.from({ length: check_count }, (_, index) => (            
              <TaskAltIcon key={`check_${index}`} color="primary" />
            ))}
          </React.Fragment>
        </td>
        <td>
          {props.current >= props.streak && (
            <i>{props.streak} is a new record!</i>            
          )}
          {props.current < props.streak && (
            <i>{props.streak} on {Model.nice_date(props.streak_date)}</i>
          )}       
        </td>
        <td className="goal_cell_monthyear">
          {props.month} / {props.year}
        </td>
        <td className="goal_cell_misses">
          {Array.from({ length: props.misses }, (_, index) => (
            <CancelIcon key={`cancel_${index}`} color="primary" />
            ))}        
        </td>
         </>
  );
}

export default Goal;