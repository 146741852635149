import * as Model from '../model';
import React, { useState, useEffect, useContext } from 'react';

import { MyContext } from '../Context';

import Goal from './Goal';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'
import { emitter } from '../eventEmitter';

function Goals(props) {
  const { sharedData } = useContext(MyContext);
  const [items, setItems] = useState([]); // the main list of goals
  const [counts, setCounts] = useState([]); // the counts for each goal
  
  const as_pills = props.as_pills
  
  useEffect(() => {
    Model.get_goals(props.date_key, setItems, (data) => { 
      setCounts(data.map(item => item.count)); 
      //setSubids(data.map(item => item.count)); 
    } );
  }, [props.date_key]); 	
  
    
  const incrementGoal = (index, id, old_count, sub_id, old_sub_id) => {    
    //console.log('incrementGoal1: ', index, id, old_count, sub_id, old_sub_id)
    if( old_count == null){
      // selected the none option, zero it out
      console.log('old_count == null')
      old_count = 1;
      sub_id = 0;
    }else if(sub_id > 0 && sub_id === old_sub_id){
      // selected the same thing, do nothing
      console.log('sub_id > 0 && sub_id === old_sub_id')
      old_count = 1
    }else if(sub_id && old_sub_id){
      console.log('sub_id && old_sub_id')
      // selected the same thing, do nothing
    }else if(old_count >= 1){ 
      // increments (turned on, so turn off)
      console.log('old_count >= 1')
      old_count = 0 
    }else{ 
      console.log('else')
      // it was zero, set to 1
      old_count = 1 
    }
    //console.log('incrementGoal2: ', old_count)
    Model.click_tag(id, sharedData.date_str, old_count, sub_id, () => {
      Model.get_goals(sharedData.date_str, setItems, (data) => { 
        setCounts(data.map(item => item.count)); 
        emitter.emit('tagUpdate', { message: "tagUpdate" });
      } );      
    });
    
  };
  
  return as_pills ? (
    <Grid container spacing={1} direction="row" justifyContent="left" alignItems="left">
          {items.map((item,index) => (
            <Grid key={`tb_${index}`} item >
              <React.Fragment key={`goalfr_${index}`}>
                <Goal name={item.name} 
                  date_key={props.date_key}
                  options={item.options} type={item.type} 
                  streak={item.streak} misses={item.misses} 
                  month={item.month} year={item.year} 
                  record={item.record}
                  streak_date={item.streak_date}
                  current={item.current}
                  count={counts[index]}
                  index={index}
                  id={item.id}
                  sub_id={item.sub_id}
                  onIncrementGoal={ incrementGoal } 
                  as_pill={true}
                />
              </React.Fragment>
            </Grid>
          ))}      
    </Grid>
  ) : (
      <Paper elevation={0}>
        <table cellPadding={5}>
          <tbody>
          <tr style={{borderBottom: '0px solid white'}}>
            <td>
              Goals
            </td>
            <td>
              Current
            </td>
            <td>
              Highest
            </td>
            <td className="goal_cell_monthyear">
              # Mon/Year
            </td>
            <td className="goal_cell_misses">
              Misses
            </td>          
          </tr>
          {items.map((item,index) => (
            <tr key={`goal_${index}`}>
            <React.Fragment key={`goalfr_${index}`}>
              <Goal name={item.name} 
                date_key={props.date_key}
                options={item.options} type={item.type} 
                streak={item.streak} misses={item.misses} 
                month={item.month} year={item.year} 
                record={item.record}
                streak_date={item.streak_date}
                current={item.current}
                count={counts[index]}
                index={index}
                id={item.id}
                sub_id={item.sub_id}
                onIncrementGoal={ incrementGoal } 
              />
            </React.Fragment>
            </tr>
          ))}      
          </tbody>
        </table>    
      </Paper>
    
  );
}

export default Goals;